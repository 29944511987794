import { H2 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Stream } from '@cloudflare/stream-react'
import Spinner from '@berlitz/spinner'
import { InlineSpinner, Overrides } from './style'
import VideoPlayer from '@berlitzplatforms/micro.ui.video-player'

interface IVideoModal {
  open: boolean
  onClose: () => void
  title?: string
  src: string
  width?: string
  height?: string
  hideControls?: boolean
  playing?: boolean
}

export const VideoWrapper = styled.div`
  video:focus {
    outline: none;
  }
`

const VideoModal: React.FC<IVideoModal> = ({
  open,
  onClose,
  title,
  src,
  height = '500px',
  width = '100%',
  hideControls,
  playing,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const isCloudflare = src.includes('cloudflare')
  const isDacast = src.includes('dacast')

  let source = ''
  if (isCloudflare) {
    source = src.split('/').pop() ?? ''
  } else if (isDacast) {
    source = src.split('/').pop() ?? ''
    const chunks = src.split('/').slice(-3)
    source = [chunks[1], chunks[0], chunks[2]].join('-')
  } else {
    source = src
  }

  return (
    <Modal
      open={open}
      size="lg"
      onClose={() => {
        setPlayerLoaded(false)
        onClose()
      }}
    >
      <VideoWrapper>
        {!playerLoaded && (
          <InlineSpinner>
            <Spinner />
          </InlineSpinner>
        )}

        {isCloudflare && (
          <Stream
            src={source}
            width={width}
            height={height}
            controls={!hideControls}
            autoplay={playing}
            onLoadedData={() => setPlayerLoaded(true)}
          />
        )}

        {isDacast && (
          <Overrides width={width} height={height}>
            <VideoPlayer
              regularPlayer
              controls
              type="dacast"
              url={source}
              playing={playing}
              onReady={() => setPlayerLoaded(true)}
            />
          </Overrides>
        )}

        {!isCloudflare && !isDacast && (
          <ReactPlayer
            url={source}
            width={width}
            height={height}
            controls={!hideControls}
            playing={playing}
            onReady={() => setPlayerLoaded(true)}
          />
        )}
      </VideoWrapper>

      <Spacer size="md" />

      {playerLoaded && title && (
        <H2 disableMargin>
          <FormattedMessage id={title} />
        </H2>
      )}

      <Spacer size="md" />
    </Modal>
  )
}

export default VideoModal
