import Spacer from '@berlitz/spacer'
import React from 'react'
import { Col } from 'react-styled-flexboxgrid'
import { Card } from './style'

const WidgetCard: React.FC<{
  children: React.ReactNode
  dataPermission?: string
  customStyling?: React.CSSProperties
}> = ({ children, dataPermission, customStyling }) => (
  <Col xs={12} sm={6} md={4} lg={4} data-c-permission={dataPermission}>
    <Card style={customStyling}>{children}</Card>
    <Spacer size="lg" />
  </Col>
)

export default WidgetCard
