import { ArrowUp } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import PortalPrograms from '@classes/Programs'
import { StoreProps } from '@interfaces/StoreState'
import HeroHeader from '@layouts/Dashboard/HeroBlock/HeroHeader'
import HeroSubHeader from '@layouts/Dashboard/HeroBlock/HeroSubHeader'
import { FlexContent } from '@layouts/Dashboard/HeroBlock/interface'
import { GET_FLEX_CONTENT } from '@queries/contentStack'
import { PROFILE_TYPE } from '@utils/constants'
import React from 'react'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Header, HeaderContainer, Hidden, Left, Right, Tab, Visible, Wrapper } from './style'
import DashboardHeader from '../Header'

const HeroBlockMaintenance = () => {
  const { profile, locale } = useSelector(({ userProfile, intl, dashboard }: StoreProps) => ({
    profile: userProfile?.info,
    locale: intl.locale,
    programs: new PortalPrograms(userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []),
    activeMaterial: dashboard.activeMaterial,
  }))
  const [headerOpen, setHeaderOpen] = React.useState(false)

  if (profile.Type !== PROFILE_TYPE.STUDENT) {
    return null
  }

  return (
    <Query query={GET_FLEX_CONTENT(locale)} fetchPolicy="network-only">
      {query => {
        const { loading, data, error } = query

        if (error) {
          return null
        }

        const content: FlexContent = data?.contentstack?.data ? JSON.parse(data.contentstack.data)?.flex_hero : {}

        return (
          <>
            <DashboardHeader
              loading={loading}
              LPUnavailable
              LPUnavailableLabels={{
                titleId: content?.maintenance_title || '',
                descriptionId: content?.maintenance_description || '',
                defaultTitle: content?.maintenance_title || '',
                defaultDescription: content?.maintenance_description || '',
              }}
            />

            <Visible view="md">
              <Wrapper>
                <Left>
                  <HeaderContainer>
                    <Header onClick={() => setHeaderOpen(!headerOpen)}>
                      <HeroHeader content={content} loading={loading} />
                      <ArrowUp color="blue100" size="md" className={headerOpen ? '' : 'icon-rotate'} />
                    </Header>
                    <Row>
                      <Col xs={12}>
                        <HeroSubHeader content={content} loading={loading} hide={!headerOpen} />
                      </Col>
                      <Col xs={12}></Col>
                    </Row>
                  </HeaderContainer>
                </Left>
              </Wrapper>
            </Visible>
            <Wrapper>
              <Row>
                <Col xs={12} lg={4}>
                  <Hidden view="md">
                    <Left>
                      <HeroHeader content={content} loading={loading} newStudent={true} />
                      <HeroSubHeader
                        content={{
                          ...content,
                          body: content.maintenance_body,
                          orientation_video_cta: content.maintenance_orientation_video_cta,
                        }}
                        loading={loading}
                        newStudent={true}
                      />
                    </Left>
                  </Hidden>
                </Col>

                <Col xs={12} lg={8}>
                  <Right>
                    <div>
                      <Tab>
                        <FormattedMessage id="Next activities" />
                      </Tab>
                    </div>
                    <div></div>
                  </Right>
                  <Spacer size="lg" />
                </Col>
              </Row>
            </Wrapper>
          </>
        )
      }}
    </Query>
  )
}

export default HeroBlockMaintenance
