import Spacer from '@berlitz/spacer'
import FormattedHTMLMessage from '@components/FormattedHTMLMessage'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { Description, Details, Title, Wrapper } from './style'

export interface IPLPUnavailableAlert {
  loading?: boolean
  titleId: string
  descriptionId: string
  defaultTitle?: string
  defaultDescription?: string
}

const LPUnavailableAlert: React.FC<IPLPUnavailableAlert> = ({
  loading,
  titleId,
  descriptionId,
  defaultTitle = 'Your learning path is currently unavailable.',
  defaultDescription = "Sorry for the inconvenience, we'll be back up and running as fast as possible.",
}) => {
  return (
    <>
      <Wrapper>
        <Details>
          <img src="/images/bug.svg" width={'60px'} />
          {loading ? (
            <div style={{ width: '230px' }}>
              <Title>
                <ContentLoader height={38} width={500} speed={2} primaryColor="#ffffff" secondaryColor="#ecebeb" />
              </Title>
              <div style={{ width: '180px' }}>
                <Description>
                  <ContentLoader height={38} width={500} speed={2} primaryColor="#ffffff" secondaryColor="#ecebeb" />
                </Description>
              </div>
            </div>
          ) : (
            <div>
              <FormattedHTMLMessage id={titleId} defaultMessage={defaultTitle} ParentComponent={Title} />
              <FormattedHTMLMessage
                id={descriptionId}
                defaultMessage={defaultDescription}
                ParentComponent={Description}
              />
            </div>
          )}
        </Details>
      </Wrapper>

      <Spacer size="lg" />
    </>
  )
}

export default LPUnavailableAlert
