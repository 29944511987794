import ImagePromoteBulk from '@berlitzplatforms/micro.image.promote-instructors'
import { StoreProps } from '@interfaces/StoreState'
import Link from 'next/link'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { WidgetCard } from '../Common'
import { H3 } from '@berlitz/globals'
import { JoinButton } from '@components/JoinLessonButton/style'
import { PromoteFMIWrapper, Wrapper } from './style'
import PortalProgram from '@classes/Program'

export interface IPromoteFMIContent {
  isBookingTab?: boolean
  isSingleBooking?: boolean
}

export const PromoteFMIContent: React.FC<IPromoteFMIContent> = ({ isBookingTab, isSingleBooking }) => {
  const intl = useIntl()
  const messages = {
    single: defineMessages({
      title: {
        id: 'Try our new Instructor Booking Feature',
        defaultMessage: 'Try our new Instructor Booking Feature',
      },
      description: {
        id: 'Schedule lessons in a few easy steps.',
        defaultMessage: 'Schedule lessons in a few easy steps.',
      },
      button: {
        id: 'Book lessons',
        defaultMessage: 'Book lessons',
      },
    }),
    bulk: defineMessages({
      title: {
        id: 'Try our new Instructor Bulk Booking Feature',
        defaultMessage: 'Try our new Instructor Bulk Booking Feature',
      },
      description: {
        id: 'Schedule all your lessons in a few easy steps.',
        defaultMessage: 'Schedule all your lessons in a few easy steps.',
      },
      button: {
        id: 'Book all lessons',
        defaultMessage: 'Book all lessons',
      },
    }),
  }
  const message = messages[isSingleBooking ? 'single' : 'bulk']

  return (
    <Wrapper isBookingTab={isBookingTab}>
      <PromoteFMIWrapper>
        <H3>{intl.formatMessage(message?.title)}</H3>
        <p>{intl.formatMessage(message?.description)}</p>
        <Link href={`/scheduling/instructors${!isSingleBooking ? '/bulk' : ''}`} legacyBehavior>
          <JoinButton>{intl.formatMessage(message?.button)}</JoinButton>
        </Link>

        <section>{isBookingTab ? <ImagePromoteBulk width={244} /> : <ImagePromoteBulk />}</section>
      </PromoteFMIWrapper>
    </Wrapper>
  )
}

const PromoteFMI: React.FC<IPromoteFMIContent> = (props) => {
  const hidePromotion = useSelector(
    ({ userProfile }: StoreProps) =>
      !userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers?.some(({ ProgramStudentInfo }) => {
        const program = new PortalProgram(ProgramStudentInfo)
        return program?.promoteFMIBulk()
      })
  )

  if (hidePromotion) {
    return null
  }

  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <PromoteFMIContent {...props} />
    </WidgetCard>
  )
}

export default PromoteFMI
