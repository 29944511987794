import Alert from '@berlitz/alert'
import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import { StoreProps } from '@interfaces/StoreState'
import { AlertWrapper } from '@layouts/Dashboard/Layout/style'
import { PROFILE_TYPE } from '@utils/constants'
import { getBlockedPrograms } from '@utils/helpers/programs'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const PaymentAlert: React.FC = () => {
  const { programs, isStudent } = useSelector(({ userProfile }: StoreProps) => ({
    programs: userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? [],
    isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
  }))

  if (isStudent && !!getBlockedPrograms(programs).length) {
    return (
      <AlertWrapper>
        <Alert type="danger">
          <FormattedMessage id="There is a payment issue with your account. Please contact your learning center or raise a case." />
          <span style={{ marginLeft: '16px' }}>
            <Button onClick={() => Router.push('/support/contact-us/?id=payments')}>
              <FormattedMessage id="Raise a case" />
            </Button>
          </span>
        </Alert>
        <Spacer size="lg" />
      </AlertWrapper>
    )
  }

  return null
}

export default PaymentAlert
