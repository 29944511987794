import { useMutation } from '@apollo/react-hooks'
import DashboardHeroBanner from '@berlitzplatforms/micro.ui.dashboard-hero-banner'
import PortalLearningPath from '@classes/LearningPath'
import FormattedHTMLMessage from '@components/FormattedHTMLMessage'
import { LoadingModal } from '@components/Modal'
import AllYouCanLearnConfirmationModal from '@components/Modal/AllYouCanLearnConfirmationModal'
import useEnsureNoDisplayedModal from '@hooks/useEnsureNoDisplayedModal'
import { PUT_REGISTRATION_FLEX_LEVEL_STATUS } from '@queries/registrations'
import { FLEX_LEVEL_STATUS } from '@utils/constants'
import router from 'next/router'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { BannerContainer } from './style'

const DasboardHeroBannerContainer: React.FC<{ path: PortalLearningPath | undefined }> = ({
  path = new PortalLearningPath(),
}) => {
  const intl = useIntl()
  const [updateFlexLevelStaus, { loading: updatingFlexLevel }] = useMutation(PUT_REGISTRATION_FLEX_LEVEL_STATUS, {
    onCompleted: () => {
      close()
      location.reload()
    },
  })
  const [openRNLModal, setOpenRNLModal] = useState(false)
  const translate = useIntl()
  // start Modal actions
  const close = () => {
    localStorage.setItem(path.tempFlags.ReadyForNextLvl, 'true')
    setOpenRNLModal(false)
  }

  useEnsureNoDisplayedModal(() => {
    if (path && path.IsOnDemand && path.ReadyForNextLvl && !localStorage.getItem(path.tempFlags.ReadyForNextLvl)) {
      setOpenRNLModal(true)
    }
  }, [path])

  const goToNextLevel = () => {
    updateFlexLevelStaus({
      variables: {
        registrationInput: [
          {
            RegistrationInfo: {
              RegistrationId: path.Material?.RegistrationId,
              FlexLevelStatus: FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS,
            },
          },
        ],
      },
    })

    // temp flag so we know that enrolment is in progress
    localStorage.setItem(path.tempFlags.EnrolmentInProgress, 'true')

    close()
  }
  // end Modal actions

  const startNextLevel = () => {
    setOpenRNLModal(true)
  }
  const goToSupport = () => {
    router.push('/support/contact-us/?id=feedback&cat=Other')
  }

  const goToBooking = () => {
    close()
    router.push(`/scheduling/single-lesson?programid=${path?.Program?.ProgramId}&unitId=0000&LPId=${path.Id}`)
  }

  const goToOtherCourses = () => {
    const url = translate.formatMessage({
      id: 'view-other-courses-link',
      defaultMessage: 'https://www.berlitz.com',
    })
    window.open(url, '_blank')
  }
  const doTranslateText = (text = '', options = {}) =>
    translate.formatMessage(
      {
        id: text,
        defaultMessage: text,
      },
      options
    )

  const bannerObj = {
    contractExpired: {
      usedAs: 'contractExpired',
      status: path?.HasContractEnded,
      onButtonClick: goToSupport,
      title: doTranslateText('Your contract has expired'),
      description: doTranslateText(
        `Interested in extending your subscription or enrolling in a different course? Contact us, we're here to help.`
      ),
      buttonLabel: doTranslateText('Contact us'),
      currentLesson: '',
    },
    allLevelsCompleted: {
      usedAs: 'allLevelsCompleted',
      status: path?.HasCompletedAllYouCanLearn,
      title: doTranslateText('Congratulations!'),
      description: (
        <FormattedHTMLMessage
          id="You've completed {lp}!"
          defaultMessage="You've completed {lp}!"
          values={{ lp: `<span style="color:#004fef;font-weight:500">${path.AYCLSequence}</span>` }}
        />
      ),
      subDescription: doTranslateText(
        path?.IsOnDemand
          ? 'Keep practicing by redoing learning activities until your course end date, or explore more Berlitz courses.'
          : 'You now have unlimited coaching sessions until your course end date.'
      ),
      buttonLabel: doTranslateText('View other courses'),
      onButtonClick: goToOtherCourses,
      currentLesson: '',
    },
    assessmentInProgress: {
      usedAs: 'assessmentInProgress',
      status: path?.IsAllLvlUnliLCS && !path?.IsPased2Days && !path.IsOnDemand,
      title: doTranslateText('An instructor is reviewing your results for level {level}', {
        level: path?.Material?.languageLevel,
      }),
      description: doTranslateText(
        'This could take a few hours. In the meantime continue with unlimited live coaching sessions and lessons.'
      ),
      currentLesson: '',
    },
    nextLevelInprogress: {
      usedAs: 'nextLevelInprogress',
      status: path?.EnrolmentInProgress,
      title: doTranslateText('{name} loading...', {
        name: `${path?.Material?.partialName}  ${Number(path?.Material?.languageLevel) + 1}`,
      }),
      description: doTranslateText('We’re preparing your next level. This could take 5 to 10 minutes.'),
      currentLesson: '',
    },
    nextLevelAvailable: {
      usedAs: 'nextLevelAvailable',
      status: path?.ReadyForNextLvl,
      title: doTranslateText('Congratulations!'),
      description: (
        <FormattedHTMLMessage
          id="You've successfully completed {lp}! You can now progress to level {lvl}."
          defaultMessage="You've successfully completed {lp}! You can now progress to level {lvl}."
          values={{
            lvl: `<span style="color: #004fef; font-weight: 500;">${path?.NextLvl}</span>`,
            lp: `<span style="color: #004fef; font-weight: 500;">${path?.MaterialCore?.MaterialName}</span>`,
          }}
        />
      ),
      buttonLabel: doTranslateText('Start next level'),
      onButtonClick: startNextLevel,
      currentLesson: '',
      nextLesson: '',
    },
  }

  const bannerType = [
    'contractExpired',
    'allLevelsCompleted',
    'assessmentInProgress',
    'nextLevelInprogress',
    'nextLevelAvailable',
  ]
  const selectedBanner = bannerType.reduce((p, key) => (bannerObj[key].status && !p ? key : p), '')
  const { currentLesson, ...bannerProps } = bannerObj[selectedBanner] || {}
  const cleanCurrentLesson = currentLesson ? doTranslateText(currentLesson) : currentLesson
  const doShowBanner = Object.keys(bannerObj).some((key) => bannerObj[key].status)
  return (
    <>
      {doShowBanner && path ? (
        <BannerContainer className={selectedBanner}>
          <DashboardHeroBanner {...bannerProps} currentLesson={cleanCurrentLesson} />
          <AllYouCanLearnConfirmationModal
            open={openRNLModal}
            onClose={close}
            onCancel={goToBooking}
            onProceed={goToNextLevel}
            isOnDemand={path.IsOnDemand}
          />
          <LoadingModal
            open={updatingFlexLevel}
            onClose={() => {}}
            title={`${intl.formatMessage({
              id: 'Updating course...',
            })}`}
            subtitle={`${intl.formatMessage({ id: 'Please wait a moment' })}.`}
          />
        </BannerContainer>
      ) : null}
    </>
  )
}

export default DasboardHeroBannerContainer
