import { useApolloClient } from '@apollo/react-hooks'
import { ClassroomLocation, Info, Profile } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import ConversationChatText from '@berlitz/streamline-icons/lib/regular/21-Messages-Chat-Smileys/03-Conversation/ConversationChatText'
import Instructor from '@berlitz/streamline-icons/lib/regular/36-School-Learning/01-School/SchoolTeacher'
import Building from '@berlitz/streamline-icons/lib/regular/49-Building-Construction/03-Buildings/Building1'
import PortalInstructor from '@classes/Instructor'
import MyBerlitzLesson from '@classes/Lesson'
import MyBerlitzLessons from '@classes/Lessons'
import PortalNextLesson from '@classes/NextLesson'
import PortalPrograms from '@classes/Programs'
import PortalRegistrations from '@classes/Registrations'
import EmptyEvents from '@components/EmptyEvents'
import JoinLessonButton from '@components/JoinLessonButton'
import { LessonTypeIcon } from '@components/ListCard'
import { RectLoader } from '@components/Loader'
import { SingleProfileModal } from '@components/Modal'
import { usePortalFeatures } from '@components/RoleBasedView'
import Tooltip from '@components/Tooltip'
import NextLessonHeader from '@components/Widgets/NextLesson/Header'
import WithUserTimeHOC, { WithUserTimeProps } from '@hocs/WithUserTime'
import { IGetEventAttendee, IGetEventAttendeeData, IGetEventsInput } from '@interfaces/Events'
import { StoreProps } from '@interfaces/StoreState'
import { TRANSLATIONS } from '@layouts/Events/constants'
import { utcfyDate } from '@layouts/Events/helpers'
import { GET_EVENT_ATTENDEE } from '@queries/events'
import { DATE_FORMAT, PROFILE_TYPE } from '@utils/constants'
import moment from 'moment'
import Link from 'next/link'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { List, ListItem, ListLoader, WidgetCard, WidgetContent } from '../Common'
import ImageSingleEvent from '@berlitzplatforms/micro.image.single-event'
import { Box, Content, Details, DetailsFooter, DetailsWrapper, NexLessonList, Title } from './style'
import EventJoinOnlineBtn from '@layouts/Events/EventJoinOnlineBtn'
import { ApolloQueryResult } from '@apollo/client'
import { flatten } from 'lodash'
import EventOrientationProvider, { EventOrientationContext } from '@layouts/Events/context/EventOrientation'
import useTimeFormat from '@hooks/useTimeFormat'
import { GET_SCHEDULES_ID } from '@queries/index'
import LessonCountdown from '@classes/LessonCountdown'

const EventType: React.FC<{ Type?: string; RecordId?: string }> = ({ Type = '', RecordId = '' }) => {
  const eventOrient = useContext(EventOrientationContext)
  return <>{eventOrient?.getType(Type, '', RecordId)}</>
}

const NextLesson: React.FC<WithUserTimeProps> = (props) => {
  const intl = useIntl()
  const client = useApolloClient()
  const { convertToUserTimeFormats } = useTimeFormat()
  const { state } = usePortalFeatures({
    configId: 'c::sidenav::events',
  })
  const { isStudent, userId, StudentPrograms, Type, ContractEndDate, UserTZ, Registrations, userPermissions } =
    useSelector(({ userProfile, permissions }: StoreProps) => {
      const { Type, Timezone, InstructorProfile } = userProfile.info
      const Registrations = new PortalRegistrations(
        userProfile.info?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers
      )
      const RegistrationId = Registrations?.FirstItem?.RegistrationId

      return {
        userPermissions: permissions.permissions,
        userId: userProfile?.info?.SFId,
        isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
        StudentPrograms: new PortalPrograms(
          userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []
        ),
        Type,
        UserTZ: Timezone,
        ContractEndDate:
          Type === 'Student'
            ? Registrations.getRegistrationById(RegistrationId).MaxContractEndDate
            : new PortalInstructor(InstructorProfile.instructorInfo).ContractEndDate,
        Registrations,
      }
    })
  const { toUserDate } = props
  const [modalOpen, toggleModal] = React.useState(false)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const isRefetching = useRef(false)
  const showCountdown = userPermissions['flag::NextLessonCountdown-feature']?.show
  const showEventCD = userPermissions['flag::NextEventCountdown-feature']?.show

  const [eventLoading, setEventLoading] = React.useState(false)
  const [userEvents, setUserEvents] = React.useState<IGetEventAttendee[]>([])

  const getUserEvents = async (defaultPayload: IGetEventsInput) => {
    setEventLoading(true)

    const RecordIds =
      (isStudent
        ? Registrations?.getEventAtteendeesPayload(defaultPayload)
        : [
            {
              ...defaultPayload,
              RecordId: userId,
            },
          ]) || []

    const promises: Promise<ApolloQueryResult<IGetEventAttendeeData>>[] = await RecordIds?.map((variables) =>
      client.query({
        query: GET_EVENT_ATTENDEE,
        fetchPolicy: 'network-only',
        variables,
      })
    )

    const res: ApolloQueryResult<IGetEventAttendeeData>[] = await Promise.all(promises)
    setEventLoading(false)
    setUserEvents(
      flatten(
        (res || [])?.map(({ data }, index) =>
          (data?.getEventAttendeev2 || []).map((event) => ({
            ...event,
            berlitzEventAttendeeInfo: {
              ...event.berlitzEventAttendeeInfo,
              RecordId: RecordIds[index]?.RecordId,
            },
          }))
        )
      )
    )
  }

  const getNext2Sessions = async (Id: string, FilterType: string) => {
    setLoading(true)
    try {
      const data = await client.query({
        query: GET_SCHEDULES_ID,
        fetchPolicy: 'network-only',
        variables: {
          Id,
          FilterType,
          Limit: 30,
          FromDate: moment.utc().format(),
          EndDate: moment.utc(moment().add(30, 'days')).format(),
        },
      })
      setData(data.data)
    } catch (e) {
      setError(!!e)
    } finally {
      setLoading(false)
      isRefetching.current = false
    }
  }

  useEffect(() => {
    let unmounted = false

    if (!unmounted && state.allowed) {
      getUserEvents({
        StartDateTime: utcfyDate(moment().tz(UserTZ).startOf('month').startOf('day')),
        EndDateTime: utcfyDate(moment(ContractEndDate, DATE_FORMAT.BASIC).tz(UserTZ).endOf('month').endOf('day')),
        RecordId: '',
        Type,
      })
    }

    return () => {
      unmounted = true
    }
  }, [state.allowed, ContractEndDate])

  useEffect(() => {
    const init = async () => {
      getNext2Sessions(userId, isStudent ? 'FILTER_STUDENT_NEXT2_SESSION' : 'FILTER_INSTRUCTOR_NEXT2_SESSION')
    }

    init()
  }, [])

  const next = new PortalNextLesson(data?.getSchedulesId, userEvents, UserTZ)
  const session = new MyBerlitzLessons(data?.getSchedulesId, UserTZ)?._firstSession
  const program = StudentPrograms.getProgram(session?.lesson?.ProgramId || '')
  const lessonWithProgram = new MyBerlitzLesson(session?.lesson, program, UserTZ)
  const firstSession = lessonWithProgram.program ? lessonWithProgram : session
  const isEvent = next.IsFirstActivityEvent && state.allowed
  const countdown = new LessonCountdown({
    StartTime: isEvent ? next.FirstEvent?.StartDatetime : firstSession?.StartTime,
    EndTime: isEvent ? next.FirstEvent?.EndDatetime : firstSession?.EndTime,
    tz: UserTZ,
  })
  const useEventStyling = isEvent && showEventCD

  useEffect(() => {
    if (!showCountdown) {
      return
    }

    const timer = setInterval(() => {
      if (countdown?.isPastEndTime && !isRefetching.current) {
        isRefetching.current = true
        clearInterval(timer)
        getNext2Sessions(userId, isStudent ? 'FILTER_STUDENT_NEXT2_SESSION' : 'FILTER_INSTRUCTOR_NEXT2_SESSION')
        if (state.allowed && showEventCD) {
          getUserEvents({
            StartDateTime: utcfyDate(moment().tz(UserTZ).startOf('month').startOf('day')),
            EndDateTime: utcfyDate(moment(ContractEndDate, DATE_FORMAT.BASIC).tz(UserTZ).endOf('month').endOf('day')),
            RecordId: '',
            Type,
          })
        }
      }
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [firstSession, state])

  return error ? (
    <div />
  ) : (
    <EventOrientationProvider>
      <WidgetCard
        dataPermission={props['data-c-permission']}
        key={firstSession?.lesson?.Id || new Date().getTime()}
        customStyling={
          useEventStyling
            ? {
                backgroundColor: '#004FEF',
                display: 'grid',
                paddingBottom: '8px',
                alignItems: 'center',
              }
            : undefined
        }
      >
        <NextLessonHeader
          isEvent={isEvent}
          loading={eventLoading || loading}
          lesson={firstSession}
          countdown={countdown}
          startDate={isEvent ? next.EventDate : firstSession?.getUserStartTime(toUserDate)}
          timerange={convertToUserTimeFormats(
            isEvent ? [next.EventStartTime, next.EventEndTime] : firstSession?.getStartEndTime(toUserDate),
            [DATE_FORMAT.HM, DATE_FORMAT.HM],
            [DATE_FORMAT.hmm, 'default']
          )?.join(' - ')}
        />

        <WidgetContent
          footerless
          contentColor="brandPrimary"
          customStyling={useEventStyling ? { height: 'auto' } : undefined}
        >
          <Content useEventStyling={useEventStyling}>
            {(() => {
              if (eventLoading || loading) {
                return (
                  <>
                    <Spacer size="md" />
                    <Title>
                      <RectLoader height="20px" />
                    </Title>

                    <Details>
                      <NexLessonList>
                        <ListLoader withLeftIcon listCount={3} />
                      </NexLessonList>
                    </Details>
                  </>
                )
              }

              if (isEvent) {
                const recId = next.FirstEvent?.RecordId ? `&recordId=${next.FirstEvent?.RecordId}` : ''
                return (
                  <Box>
                    <DetailsWrapper>
                      <Details className="event" useEventStyling={useEventStyling}>
                        <div>
                          {!next?.FirstEvent?.EventDisplayName ? (
                            <div className="type">
                              <EventType Type={next?.FirstEvent?.EventType} RecordId={next?.FirstEvent?.RecordId} />
                            </div>
                          ) : null}

                          <Spacer size="xxs" />

                          <div className="language">
                            <div>
                              {`${intl.formatMessage({
                                id: TRANSLATIONS.DELIVERY_LANGUAGE,
                                defaultMessage: TRANSLATIONS.DELIVERY_LANGUAGE,
                              })}:`}
                            </div>
                            <div className="tag">{next?.FirstEvent?.Language}</div>
                          </div>

                          {next?.FirstEvent?.EventDisplayName ? (
                            <div className="with-name">
                              <div className="name">{next?.FirstEvent?.EventDisplayName}</div>
                              <div className="type">
                                <EventType Type={next?.FirstEvent?.EventType} RecordId={next?.FirstEvent?.RecordId} />
                              </div>
                            </div>
                          ) : null}

                          <Link
                            href={`/events/?id=${next.FirstEvent?.EventId}&start=${next.FirstEvent?.StartDatetime}&end=${next.FirstEvent?.EndDatetime}${recId}`}
                          >
                            {intl.formatMessage({
                              id: TRANSLATIONS.MORE_INFO,
                              defaultMessage: TRANSLATIONS.MORE_INFO,
                            })}
                          </Link>
                        </div>

                        <ImageSingleEvent />
                      </Details>
                    </DetailsWrapper>
                    <div />

                    <DetailsFooter>
                      <EventJoinOnlineBtn berlitzEventAttendeeInfo={next?.FirstEvent} />
                    </DetailsFooter>
                  </Box>
                )
              }

              if (!firstSession?.lesson) {
                return (
                  <>
                    <Spacer size="md" />
                    <EmptyEvents
                      buttonLabel={intl.formatMessage({ id: 'Schedule lessons' })}
                      buttonLink={`${!isStudent ? '/instructors' : ''}/scheduling/`}
                      noButtons={!isStudent}
                    />
                  </>
                )
              }

              return (
                <Box>
                  {firstSession?.lesson?.ProgramName && (
                    <div>
                      <Spacer size="xs" />
                      <Title>{firstSession?.lesson?.ProgramName}</Title>
                    </div>
                  )}

                  <DetailsWrapper>
                    <Details>
                      <NexLessonList>
                        <List>
                          {firstSession?._user && (
                            <ListItem>
                              {isStudent ? <Instructor /> : <Profile />}
                              {isStudent && firstSession?._userId ? (
                                <Link href={`/instructor/?id=${firstSession?._userId}`}>{firstSession?._user}</Link>
                              ) : (
                                <a className="customer-name" onClick={() => toggleModal(true)}>
                                  {firstSession?._user}
                                </a>
                              )}
                            </ListItem>
                          )}
                          {firstSession?.lesson?.LearningCenter && !firstSession?._isProgramOnline && (
                            <ListItem>
                              <Building />
                              {firstSession?.lesson?.LearningCenterId ? (
                                <Link href={`/learning-center/?id=${firstSession?.lesson?.LearningCenterId}`}>
                                  {firstSession?.lesson?.LearningCenter}
                                </Link>
                              ) : (
                                <a>{firstSession?.lesson?.LearningCenter}</a>
                              )}
                            </ListItem>
                          )}
                          <ListItem>
                            {firstSession?._isProgramOnline ? <Building /> : <ClassroomLocation />}
                            {firstSession?._location ? (
                              <span>{firstSession?._location}</span>
                            ) : (
                              <>
                                <span>
                                  <FormattedMessage id="Room to be confirmed" />
                                </span>
                                <span className="room-to-be-confirmed">
                                  <Tooltip
                                    message={`${intl.formatMessage({
                                      id: 'Your room will be confirmed after 5pm the day before your class',
                                    })}.`}
                                    placement="top"
                                  >
                                    <Info size="xs" />
                                  </Tooltip>
                                </span>
                              </>
                            )}
                          </ListItem>
                          {firstSession?.lesson?.ForCounselling && (
                            <ListItem>
                              <ConversationChatText />
                              <FormattedMessage id="Counseling" />
                            </ListItem>
                          )}
                        </List>
                      </NexLessonList>
                      {firstSession?.getTypeName(isStudent) && (
                        <div>
                          <LessonTypeIcon
                            typeName={firstSession?.getTypeName(isStudent)}
                            customLabel={firstSession.LCSTitle || ''}
                          />
                        </div>
                      )}
                    </Details>
                  </DetailsWrapper>
                  <div />

                  <DetailsFooter>
                    <JoinLessonButton
                      lesson={firstSession?.lesson}
                      fullWidth
                      studentId={isStudent ? null : firstSession?._userId}
                      endTimeOverride={firstSession?.EndTime}
                    />
                  </DetailsFooter>

                  {!isStudent && !!firstSession?.lesson?.CustomerId && (
                    <SingleProfileModal
                      open={modalOpen}
                      onClose={() => toggleModal(false)}
                      StudentId={firstSession?._userId}
                      StudentName={firstSession?._user}
                      Language={firstSession?.lesson?.Language ?? ''}
                    />
                  )}
                </Box>
              )
            })()}
          </Content>
        </WidgetContent>
      </WidgetCard>
    </EventOrientationProvider>
  )
}

export default WithUserTimeHOC(NextLesson)
