import Button from '@berlitz/button'
import Help from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/14-Alerts/QuestionCircle'
import Email from '@berlitz/streamline-icons/lib/regular/19-Emails/05-Envelopes/Envelope'
import PortalCases from '@classes/Cases'
import Badge from '@components/Badge'
import IconWrapper from '@components/IconWrapper'
import { CaseData } from '@interfaces/Cases'
import { StoreProps } from '@interfaces/StoreState'
import { PROFILE_TYPE } from '@utils/constants'
import { getStatusColor } from '@utils/helpers/cases'
import get from 'lodash/get'
import Link from 'next/link'
import Router from 'next/router'
import * as React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  List,
  ListItem,
  ListItemContainer,
  ListLoader,
  WidgetCard,
  WidgetContent,
  WidgetFooter,
  WidgetHeader,
} from '../Common'
import QuerySupportData from '../QuerySupportData'
import { LinkProp } from './interface'
import { SupportList } from './style'

const Support: React.FC<InjectedIntlProps> = (props) => {
  const { intl } = props
  const { isStudent, userId, tz } = useSelector(({ userProfile }: StoreProps) => ({
    userId: userProfile?.info?.SFId,
    isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
    tz: userProfile?.info.Timezone,
  }))
  const defaultLinks: LinkProp[] = [
    {
      icon: <Email color="brand07" />,
      href: '/support/contact-us',
      name: intl.formatMessage({ id: 'Contact us' }),
    },
    {
      icon: <Help color="brand07" />,
      href: '/support',
      name: intl.formatMessage({ id: 'FAQs' }),
    },
  ]
  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <WidgetHeader
        icon={
          <IconWrapper>
            {' '}
            <Help />
          </IconWrapper>
        }
        title={intl.formatMessage({ id: 'Support' })}
        color="disabled"
      />
      <QuerySupportData Id={userId} Type={isStudent ? 'Student' : 'Instructor'}>
        {({ loading, error, data }) => {
          const cases = new PortalCases(get(data, 'getCases', []), tz)
          const supports: CaseData[] = cases.SortedCases || []

          return (
            <>
              <WidgetContent>
                <SupportList>
                  {error ? (
                    <div />
                  ) : loading ? (
                    <ListLoader listCount={3} />
                  ) : (
                    <List>
                      {supports.slice(0, 10).map(({ CaseInfo }, index: number) => {
                        const caseId = get(CaseInfo, 'Id')
                        const caseNumber = get(CaseInfo, 'CaseNumber', caseId)
                        const subject = get(CaseInfo, 'Subject')

                        return (
                          <ListItem key={`support-item-${index}`}>
                            {caseId && (
                              <ListItemContainer>
                                <Link href={`/support/case/?id=${caseId}`}>
                                  {subject && subject}
                                  {!subject && (
                                    <>
                                      <FormattedMessage id="Case number" /> {caseNumber}
                                    </>
                                  )}
                                </Link>
                                <Badge color={getStatusColor(CaseInfo.Status)} label={CaseInfo.Status || ''} />
                              </ListItemContainer>
                            )}
                          </ListItem>
                        )
                      })}

                      {!supports.length &&
                        defaultLinks.map(({ icon, href, name }, index: number) => (
                          <ListItem key={`support-item-${index}`}>
                            <IconWrapper> {icon}</IconWrapper>
                            <Link href={href}>{name}</Link>
                          </ListItem>
                        ))}
                    </List>
                  )}
                </SupportList>
              </WidgetContent>
              <WidgetFooter multipleButtons>
                {!!supports?.length && (
                  <Button onClick={() => Router.push('/support/requests')} fullWidth outline>
                    <FormattedMessage id="Previous requests" />
                  </Button>
                )}
                <Button onClick={() => Router.push('/support/contact-us')} fullWidth>
                  <FormattedMessage id="Raise case" />
                </Button>
              </WidgetFooter>
            </>
          )
        }}
      </QuerySupportData>
    </WidgetCard>
  )
}

// export default Support

export default injectIntl(Support)
