import RoleBasedView from '@components/RoleBasedView'
import DashboardHeader from '@layouts/Dashboard/Header'
import HeroBlock from '@layouts/Dashboard/HeroBlock'
import HeroBlockMaintenance from '@layouts/Dashboard/HeroBlock/HeroBlockMaintenance'
import PaymentAlert from '@layouts/Dashboard/PaymentAlert'
import React from 'react'
import DashboardWidgetSection from './DashboardWidgetSection'
import { WidgetWrapper } from './style'
import QueryDashboard from './QueryDashboard'
import HeroBlockInitializer from '../HeroBlock/HeroBlockInitializer'

const Dashboard: React.FC = () => (
  <>
    <PaymentAlert />
    <RoleBasedView configId="c::dashboard::HeroBlock" OverrideComponent={<HeroBlockMaintenance />}>
      <>
        <RoleBasedView configId="c::dashboard::DashboardHeaderNotification" OverrideComponent={<DashboardHeader />}>
          <DashboardHeader
            LPUnavailable
            LPUnavailableLabels={{
              titleId: 'dashboard-header-notification-title',
              descriptionId: 'dashboard-header-notification-description',
              defaultTitle: 'Important: Lesson recording availability is changing soon!',
              defaultDescription: 'Download your lesson recordings for future reference',
            }}
          />
        </RoleBasedView>
        <HeroBlockInitializer>
          <HeroBlock />
        </HeroBlockInitializer>
      </>
    </RoleBasedView>
    <WidgetWrapper>
      <QueryDashboard>
        {({ loading, error, LearningPaths, Achievements }) => {
          return (
            <DashboardWidgetSection
              LearningPaths={LearningPaths}
              Achievements={Achievements}
              isLoading={loading}
              hasError={error}
            />
          )
        }}
      </QueryDashboard>
    </WidgetWrapper>
  </>
)

export default React.memo(Dashboard)
