import styled from 'styled-components'

export const BannerContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.md};
  background-color: ${({ theme }) => theme.palette.white};
  border: 2px solid ${({ theme }) => theme.palette.blue100};
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
  border-radius: ${({ theme }) => theme.radius.lg};

  &.contractExpired,
  &.assessmentInProgress,
  &.nextLevelInprogress .sc-qapaw.hlGAQW .sc-oTmZL.jVInjV {
    padding-bottom: ${({ theme }) => theme.space.md};
    padding-left: ${({ theme }) => theme.space.md};
    padding-right: ${({ theme }) => theme.space.md};
    padding-top: ${({ theme }) => theme.space.xs};
  }

  &.nextLevelInprogress {
    .sc-qapaw.hlGAQW .sc-oTmZL.jVInjV {
      div:last-child,
      div:nth-child(3) span {
        display: none;
      }
    }
  }

  &.allLevelsCompleted {
    div div {
      h4 {
        padding-top: ${({ theme }) => theme.space.xs};
      }
      div:not(:last-child) {
        padding-left: ${({ theme }) => theme.space.sm};
        padding-right: ${({ theme }) => theme.space.sm};
      }

      div:last-child {
        border-bottom-right-radius: ${({ theme }) => theme.radius.lg};
        border-bottom-left-radius: ${({ theme }) => theme.radius.lg};
      }
    }
  }

  &.nextLevelAvailable div div div:nth-child(5) {
    display: none;
  }
`
