import * as React from 'react'

import { WidgetContentProp } from './interface'
import { Content } from './style'

const WidgetContent: React.FC<WidgetContentProp> = ({ children, contentColor, footerless, customStyling }) => (
  <Content footerless={footerless} contentColor={contentColor} style={customStyling}>
    {children}
  </Content>
)

export default WidgetContent
