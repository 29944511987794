import Button from '@berlitz/button'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import PortalPrograms from '@classes/Programs'
import { StoreProps } from '@interfaces/StoreState'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { WidgetCard } from '../Common'
import { Icon, Label, Wrapper } from './style'

const UnbookedLessons: React.FC = (props) => {
  const program = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      dashboard.activeProgram &&
      new PortalPrograms(
        userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []
      ).getProgram(dashboard.activeProgram)
  )

  if (!program || program.isFlexProgram() || program.areLessonsAllBooked()) {
    return null
  }

  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <Wrapper>
        <Icon>
          <Calendar1 />
        </Icon>

        <Label>
          <FormattedMessage id="You have unbooked lessons" />
        </Label>

        <Button onClick={() => Router.push('/scheduling')} ghost>
          <FormattedMessage id="Schedule lessons" />
        </Button>
      </Wrapper>
    </WidgetCard>
  )
}

export default UnbookedLessons
