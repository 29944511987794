import { H1 } from '@berlitz/globals'
import styled from 'styled-components'
import Avatar from '../../../components/Avatar'

export const StyledAvatar = styled(Avatar)`
  margin-right: ${({ theme }) => theme.space.md};

  svg {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: white;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;

  div {
    p {
      font-size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.palette.blueGray60};
    }

    ${H1} {
      line-height: 1;
    }
  }
`

export const SearchBar = styled.div`
  position: relative;
  margin: ${({ theme }) => `${theme.space.lg} 0`};

  p {
    padding: ${({ theme }) => theme.space.xs};
    position: absolute;
    z-index: 1;
  }
`

export const AlertWrapper = styled.div`
  & > div {
    width: 100%;
  }
  div > span:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    & > div {
      width: 100%;
      & > span {
        display: flex;
        & > span:nth-child(2) {
          text-align: right;
          flex: 1;
        }
      }
    }
  }
`

export const WidgetWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    & > div > div {
      max-width: 381px;
    }
  }
`
