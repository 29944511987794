import Button from '@berlitz/button'
import AlarmBell from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/20-Alert/AlarmBell'
import IconWrapper from '@components/IconWrapper'
import { StoreProps } from '@interfaces/StoreState'
import { hasNotifications } from '@utils/helpers/programs'
import Link from 'next/link'
import React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { WidgetCard, WidgetContent, WidgetHeader } from '../Common'
import { Suggestion } from './style'

const Notifications: React.FC<InjectedIntlProps> = (props) => {
  const { intl } = props
  const { programs } = useSelector(({ userProfile }: StoreProps) => ({
    programs: userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? [],
  }))

  if (!hasNotifications(programs)) {
    return null
  }
  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <WidgetHeader
        icon={
          <IconWrapper>
            {' '}
            <AlarmBell />
          </IconWrapper>
        }
        title={intl.formatMessage({ id: 'Notifications' })}
        color="info"
      />
      <WidgetContent footerless>
        {programs.map(({ ProgramStudentInfo }, index) => {
          if (
            ProgramStudentInfo.ValidforCounselling &&
            ProgramStudentInfo.LessonsTaken / ProgramStudentInfo.TotalLessons >= 0.5
          ) {
            return (
              <Suggestion key={`sugg-${index}`}>
                <div className="program-name">{ProgramStudentInfo.ProgramName}</div>
                <div className="content">
                  <div>
                    <FormattedMessage id="A counselling session is available" />
                  </div>
                  <div>
                    <Link
                      href={`/support/contact-us/?id=my-lessons&pid=${ProgramStudentInfo.ProgramId}&cat=Request counseling`}
                    >
                      <Button small>
                        <FormattedMessage id="Raise a case" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </Suggestion>
            )
          }

          return null
        })}
      </WidgetContent>
    </WidgetCard>
  )
}

export default injectIntl(Notifications)
