import styled from 'styled-components'
import { ListItem } from '../Common'

export const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Header = styled.div<{ isEvent?: boolean; useEventStyling?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    width: 150px;
  }

  ${({ theme, isEvent, useEventStyling }) =>
    isEvent &&
    `
      align-items: flex-start;

      .title {
        font-size: ${theme.fontSize.xs};
        width: 100%;
        ${useEventStyling ? 'min-height: 36px' : 'height: 36px'};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .date-time {
        font-size: ${theme.fontSize.xxs};
        font-weight: ${theme.fontWeight.bold};
        display: flex;
        justify-content: space-between;
      }
    `}
`

export const DateTime = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 36px;

  div:last-child {
    margin-top: ${({ theme }) => theme.space.xxs};
  }
`

export const Content = styled.div<{ useEventStyling?: boolean }>`
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  margin: ${({ theme }) => `0 ${theme.space.xxs} ${theme.space.xxs}`};
  border-radius: 3px;
  height: 212px;
  max-height: 212px;
  ${({ useEventStyling }) =>
    useEventStyling
      ? `
    height: auto;
    max-height: none;
  `
      : ''}
`

export const Title = styled.div`
  background-color: ${({ theme }) => theme.palette.blue20};
  padding: ${({ theme }) => theme.space.xxs};
  border-radius: ${({ theme }) => theme.radius.sm};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  color: ${({ theme }) => theme.colors.text00};
  text-align: center;
  width: max-content;
  max-width: calc(100% - 16px);
  margin: auto;
`

export const Details = styled.div<{ useEventStyling?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.md};

  p.name {
    max-width: 146px;
  }

  ${({ theme, useEventStyling }) => `
    &.event {
      .type {
        font-size: ${theme.fontSize.xs};
        font-weight: ${theme.fontWeight.bold};
      }

      .language {
        font-size: ${theme.fontSize.xxxs};
        font-weight: ${theme.fontWeight.bold};
        display: flex;
        align-items: center;
        margin-bottom: 9px;

        .tag {
          background-color: ${theme.palette.purple100};
          padding: 4px 4px 3px;
          border-radius: 2px;
          margin-left: 3px;
          color: ${theme.palette.white};
          width: max-content;
        }
      }

      .with-name {
        .type {
          color: ${theme.palette.blueGray70};
          font-size: ${theme.fontSize.xxs};
          margin-bottom: 9px;
        }

        .name {
          color: ${theme.palette.charcoal};
          font-size: ${theme.fontSize.sm};
          font-weight: ${theme.fontWeight.bold};
          margin-bottom: 4px;
          ${
            useEventStyling
              ? `
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding-right: 4px;
          `
              : ''
          }
        }
      }

      a {
        font-size: ${theme.fontSize.xxs};
        font-weight: ${theme.fontWeight.bold};
      }
    }
  `}
`

export const NexLessonList = styled.div`
  ${ListItem} {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: ${({ theme }) => `${theme.space.xxxs} 0`};

    svg {
      color: ${({ theme }) => theme.palette.blueGray60};
      margin-right: ${({ theme }) => theme.space.xs};
      font-size: 20px;
    }
  }
`

export const DetailsFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.gray40};

  > button:first-child {
    outline: 1px solid ${({ theme }) => theme.palette.gray40};
    margin: ${({ theme }) => theme.space.sm};
    width: calc(100% - ${({ theme }) => theme.space.sm} - ${({ theme }) => theme.space.sm});
  }
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Countdown = styled.div<{ danger?: boolean }>`
  padding: 0px 10px;
  text-align: center;
  line-height: 1.57;
  letter-spacing: 0.49px;
  width: max-content;
  margin-top: 3px;
  margin-left: -1px;

  ${({ theme, danger }) => `
    font-size: ${theme.fontSize.xxs};
    border-radius: ${theme.space.md};
    background-color: ${danger ? theme.palette.danger100 : '#d87515'};
    border: 1px solid ${theme.palette.white};
  `}
`

export const EventCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`

export const EventSecondHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: ${({ theme }) => theme.fontSize.xxs};
`

export const EventDate = styled.div`
  text-align: right;
`
