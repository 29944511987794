import { useApolloClient } from '@apollo/react-hooks'
import Button from '@berlitz/button'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import PortalInstructor from '@classes/Instructor'
import MyBerlitzLessons from '@classes/Lessons'
import PortalRegistrations from '@classes/Registrations'
import Badge from '@components/Badge'
import { usePortalFeatures } from '@components/RoleBasedView'
import WithUserTimeHOC, { WithUserTimeProps } from '@hocs/WithUserTime'
import { IGetEventAttendee, IGetEventAttendeeData, IGetEventsInput } from '@interfaces/Events'
import { StoreProps } from '@interfaces/StoreState'
import { utcfyDate } from '@layouts/Events/helpers'
import { GET_EVENT_ATTENDEE } from '@queries/events'
import { DATE_FORMAT, PROFILE_TYPE } from '@utils/constants'
import { ApolloQueryResult } from 'apollo-client'
import { flatten, orderBy } from 'lodash'
import slice from 'lodash/slice'
import moment from 'moment'
import Router from 'next/router'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import EmptyEvents from '../../EmptyEvents'
import { List, ListItem, ListLoader, WidgetCard, WidgetContent, WidgetFooter, WidgetHeader } from '../Common'
import QueryScheduleData from '../QueryScheduleData'
import { MyCalendarList } from './style'
import PortalNextLesson from '@classes/NextLesson'
import useTimeFormat from '@hooks/useTimeFormat'

const MyCalendar: React.FC<WithUserTimeProps> = (props) => {
  const { toUserDate } = props
  const intl = useIntl()
  const client = useApolloClient()
  const { state } = usePortalFeatures({
    configId: 'c::sidenav::events',
  })
  const { convertToUserTimeFormats } = useTimeFormat()
  const { isStudent, userId, Type, ContractEndDate, UserTZ, Registrations } = useSelector(
    ({ userProfile }: StoreProps) => {
      const { Type, Timezone, InstructorProfile } = userProfile.info
      const Registrations = new PortalRegistrations(
        userProfile.info?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers
      )
      const RegistrationId = Registrations?.FirstItem?.RegistrationId

      return {
        userId: userProfile?.info?.SFId,
        isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
        Type,
        UserTZ: Timezone,
        ContractEndDate:
          Type === 'Student'
            ? Registrations.getRegistrationById(RegistrationId).MaxContractEndDate
            : new PortalInstructor(InstructorProfile.instructorInfo).ContractEndDate,
        Registrations,
      }
    }
  )

  const [eventLoading, setEventLoading] = React.useState(false)
  const [userEvents, setUserEvents] = React.useState<IGetEventAttendee[]>([])

  const getUserEvents = async (defaultPayload: IGetEventsInput) => {
    setEventLoading(true)

    const RecordIds =
      (isStudent
        ? Registrations?.getEventAtteendeesPayload(defaultPayload)
        : [
            {
              ...defaultPayload,
              RecordId: userId,
            },
          ]) || []

    const promises: Promise<ApolloQueryResult<IGetEventAttendeeData>>[] = await RecordIds?.map((variables) =>
      client.query({
        query: GET_EVENT_ATTENDEE,
        fetchPolicy: 'network-only',
        variables,
      })
    )

    const res: ApolloQueryResult<IGetEventAttendeeData>[] = await Promise.all(promises)
    setEventLoading(false)
    setUserEvents(
      flatten(
        (res || [])?.map(({ data }, index) =>
          (data?.getEventAttendeev2 || []).map((event) => ({
            ...event,
            berlitzEventAttendeeInfo: {
              ...event.berlitzEventAttendeeInfo,
              RecordId: RecordIds[index]?.RecordId,
            },
          }))
        )
      )
    )
  }

  useEffect(() => {
    let unmounted = false

    if (!unmounted && state.allowed) {
      getUserEvents({
        StartDateTime: utcfyDate(moment().tz(UserTZ).startOf('month').startOf('day')),
        EndDateTime: utcfyDate(moment(ContractEndDate, DATE_FORMAT.BASIC).tz(UserTZ).endOf('month').endOf('day')),
        RecordId: '',
        Type,
      })
    }

    return () => {
      unmounted = true
    }
  }, [state.allowed, ContractEndDate])

  const getDateTime = (time: string, format: string) => toUserDate(time, DATE_FORMAT.TZ, format)

  return (
    <QueryScheduleData
      Id={userId}
      FilterType={isStudent ? 'FILTER_STUDENT_MY_CALENDER' : 'FILTER_INSTRUCTOR_MY_CALENDER'}
    >
      {({ loading, error, data }) => {
        if (error) {
          return <div />
        }

        const sessions = new MyBerlitzLessons(
          orderBy(
            [
              ...(data?.getSchedulesId || []),
              ...(new PortalNextLesson(data?.getSchedulesId, userEvents, UserTZ)?.FilteredEvents?.map(
                ({ berlitzEventAttendeeInfo }) => ({
                  SessionId: berlitzEventAttendeeInfo?.EventAttendeeId,
                  StartTime: berlitzEventAttendeeInfo?.StartDatetime,
                  EndTime: berlitzEventAttendeeInfo?.EndDatetime,
                })
              ) || []),
            ],
            ['StartTime']
          )
        ).simplifiedSession()

        return (
          <WidgetCard dataPermission={props['data-c-permission']}>
            <WidgetHeader
              icon={<Calendar1 />}
              title={intl.formatMessage({
                id: isStudent ? 'My calendar' : 'My schedule',
              })}
              color="info"
            />
            <WidgetContent>
              <MyCalendarList>
                {loading || eventLoading ? (
                  <ListLoader withRightContent />
                ) : (
                  <>
                    {!sessions.length && <EmptyEvents icon="/images/empty-notes.svg" noButtons />}

                    <List>
                      {slice(sessions, 0, 5).map((session, index: number) => (
                        <ListItem key={`list-item-${index}`} bordered>
                          <span>{getDateTime(session?.lesson?.StartTime ?? '', 'Do MMMM')}</span>
                          <Badge
                            color="blue20"
                            label={`${convertToUserTimeFormats(
                              session.getStartEndTime(toUserDate),
                              [DATE_FORMAT.HM, DATE_FORMAT.HM],
                              [DATE_FORMAT.hmm, 'default']
                            ).join(' - ')}${
                              session?.lesson?.Status
                                ? session.getLessonCountTexts(intl)
                                : session.getEventCountTexts(intl)
                            }`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </MyCalendarList>
            </WidgetContent>
            <WidgetFooter>
              <Button
                onClick={() => Router.push(`${isStudent ? '/scheduling/my-calendar' : '/instructors/scheduling'}`)}
                fullWidth
              >
                <FormattedMessage id={isStudent ? 'View my calendar' : 'View my schedule'} />
              </Button>
            </WidgetFooter>
          </WidgetCard>
        )
      }}
    </QueryScheduleData>
  )
}

export default WithUserTimeHOC(MyCalendar)
