import styled from 'styled-components'
import { ListItem } from '../Common'

export const MyAccountList = styled.div`
  padding: 6px 0;

  ${ListItem} {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding: ${({ theme }) => `${theme.space.xs} 0`};

    svg {
      margin-right: ${({ theme }) => theme.space.xs};
      font-size: 20px;
    }
  }
`
