import PortalCases from '@classes/Cases'
import union from 'lodash/union'
import { CaseData, CustomCase } from '../../interfaces/Cases'
import { convertSFtoPortalValue } from '../../layouts/Support/typeMapping'
import { getIcon } from './fileicons'
import { getFileExtension } from './index'

export const tranformCasesData = requests => {
  const temp = new PortalCases(requests || [])
  return temp.SortedCases?.map(request => request.CaseInfo)
}

export const transformCase = function(
  { CaseInfo, FileInfos }: CaseData,
  useV2?: boolean,
  isInstructor?: boolean
): CustomCase {
  const topic = {
    CreatedDatetime: CaseInfo.CreatedDatetime,
    Subject: CaseInfo.Subject,
    Description: CaseInfo.Description,
    CreatorId: CaseInfo.CreatorId,
    Id: CaseInfo.Id,
  }
  let conversation = union(FileInfos, CaseInfo.caseCommentInfoList)

  conversation.push(topic)

  conversation = conversation
    .sort(
      (d1, d2) =>
        new Date(d1.CreatedDatetime || Date.now()).getTime() - new Date(d2.CreatedDatetime || Date.now()).getTime()
    )
    .map(item => {
      const currentItem = item
      currentItem.isUser = CaseInfo.CreatorId === item.CreatorId
      currentItem.itemId = item.Id || item.RecordId
      if (item && item.FileUrl) {
        const baseUrl = new URL(item.FileUrl)
        const ext = getFileExtension(item.Title)
        currentItem.icon = getIcon(ext).Icon
        currentItem.protectionLevel = (baseUrl.pathname || '/protected/').split('/')[1]
      }
      return currentItem
    })

  const Type =
    (useV2
      ? convertSFtoPortalValue(CaseInfo?.Type + (CaseInfo?.SubType || ''), useV2, isInstructor)
      : convertSFtoPortalValue(CaseInfo?.Type, useV2, isInstructor)) || CaseInfo?.Type
  return {
    title: `${Type}${CaseInfo.SubType ? ` - ${CaseInfo.SubType}` : ''}`,
    Subject: CaseInfo.Subject,
    Id: CaseInfo.Id,
    Status: CaseInfo.Status,
    CaseNumber: CaseInfo.CaseNumber,
    conversation,
  }
}

export const getStatusColor = (status?: string): string => {
  const mapping = {
    New: 'green',
    'On Hold': 'warning',
    Working: 'purple',
    'Re-opened': 'purple',
    Closed: 'info',
  }
  return mapping[status || 'New']
}

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}
