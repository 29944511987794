import PortalLearningPath from '@classes/LearningPath'
import { Lesson } from '@components/LPCard'
import { StoreProps } from '@interfaces/StoreState'
import FlexChapter from '@layouts/LearningPath/Chapter/Flex'
import { FEATURE_TYPE } from '@utils/constants'
import React from 'react'
import { useSelector } from 'react-redux'

interface IHeroCards {
  loading?: boolean
  FlexPaths?: PortalLearningPath
  error?: Error
  coaching?: boolean
  hasNoLCS?: boolean
}

const HeroCards: React.FC<IHeroCards> = ({ loading, FlexPaths, error, coaching, hasNoLCS }) => {
  const { FeatureType } = useSelector(({ userProfile }: StoreProps) => ({
    FeatureType: userProfile.info?.FeatureType,
  }))

  const triCards = FlexPaths?.[FlexPaths?.AreAllUnitsCompleted ? 'DashboardCompletedUnits' : 'DashboardUnits']?.slice(
    0,
    3
  )

  const displaySwitch = {
    DEFAULT: FlexPaths?.[
      FlexPaths?.AreAllUnitsCompleted ? 'DashboardCompletedActivities' : 'DashboardActivities'
    ]?.slice(0, 2),
    JAPAN_FLEX: triCards,
    ON_DEMAND: triCards,
  }

  const isJapanFlexOrOnDemand =
    FeatureType.indexOf(FEATURE_TYPE.JAPAN_FLEX) >= 0 || FeatureType.indexOf(FEATURE_TYPE.ON_DEMAND) >= 0 || hasNoLCS
  const size = coaching ? 1 : isJapanFlexOrOnDemand ? 3 : 2
  const featureKey = isJapanFlexOrOnDemand ? FEATURE_TYPE.JAPAN_FLEX : FEATURE_TYPE.DEFAULT

  if (loading) {
    return (
      <>
        {[...Array(size).keys()].map((_val, index) => (
          <div key={`card-item-${index}`} className="card-item">
            <Lesson title="Lesson 1" status="loading" />
          </div>
        ))}
      </>
    )
  }

  if (error || !FlexPaths || (coaching && !FlexPaths?.NextSessionUnit)) {
    return <></>
  }

  if (coaching && FlexPaths?.NextSessionUnit) {
    return (
      <div className="card-item">
        <FlexChapter
          Unit={FlexPaths.NextSessionUnit}
          bridgeless
          dashboard
          Path={FlexPaths}
          ActiveUnits={FlexPaths.UnlockedLCS}
          BookableUnits={FlexPaths.BookableLCS}
          CompletedOrOngoingUnits={FlexPaths.CompletedOrOngoingLCS}
        />
      </div>
    )
  }

  return (
    <>
      {(displaySwitch[featureKey] ?? []).map(Unit => (
        <div key={Unit.Id} className="card-item">
          <FlexChapter Unit={Unit} bridgeless dashboard />
        </div>
      ))}
    </>
  )
}

export default HeroCards
