import { ScheduleSession } from '@interfaces/ScheduleSession'
import { IGetEventAttendee } from '@interfaces/Events'
import { head, orderBy, sortBy } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT } from '@utils/constants'
import PortalBerlitzEventAttendee from './BerlitzEventAttendee'

class PortalNextLesson {
  lessons: ScheduleSession[]
  events: IGetEventAttendee[]
  userTZ: string

  constructor(lessons: ScheduleSession[] = [], events: IGetEventAttendee[] = [], userTZ: string = '') {
    this.lessons = lessons
    this.events = events
    this.userTZ = userTZ
  }

  get FilteredEvents() {
    return orderBy(
      (this.events || [])?.filter(event => {
        const Event = new PortalBerlitzEventAttendee(event, false, this.userTZ)
        return !Event.isPastEvent() && !Event.IsCancelled
      }) || [],
      ['berlitzEventAttendeeInfo.StartDatetime']
    )
  }

  get FirstEvent() {
    return head(this.FilteredEvents)?.berlitzEventAttendeeInfo
  }

  get EventStartDatetime() {
    return moment(this.FirstEvent?.StartDatetime)
      .tz(this.userTZ)
      .clone()
  }

  get EventEndDatetime() {
    return moment(this.FirstEvent?.EndDatetime)
      .tz(this.userTZ)
      .clone()
  }

  get EventDate() {
    return this.EventStartDatetime.format(DATE_FORMAT.DEFAULT)
  }

  get EventTime() {
    return `${this.EventStartDatetime.format(DATE_FORMAT.HM)} - ${this.EventEndDatetime.format(DATE_FORMAT.HM)}`
  }

  get EventStartTime() {
    return this.EventStartDatetime.format(DATE_FORMAT.HM)
  }

  get EventEndTime() {
    return this.EventEndDatetime.format(DATE_FORMAT.HM)
  }

  get IsFirstActivityEvent() {
    return this.MappedActivities?.type === 'event'
  }

  get MappedActivities() {
    return head(sortBy([...this.LessonIdsAndDates, ...this.EventIdsAndDates], ['date']))
  }

  get LessonIdsAndDates() {
    return (this.lessons || [])?.map(({ Id, StartTime }) => ({
      id: Id,
      date: StartTime,
      type: 'lesson',
    }))
  }

  get EventIdsAndDates() {
    return this.FilteredEvents.map(({ berlitzEventAttendeeInfo }) => ({
      id: berlitzEventAttendeeInfo.EventId,
      date: berlitzEventAttendeeInfo.StartDatetime,
      type: 'event',
    }))
  }
}

export default PortalNextLesson
