import MyAccount from './MyAccount'
import MyCalendar from './MyCalendar'
import NextLesson from './NextLesson'
import Notifications from './Notifications'
import Support from './Support'
import UnbookedLessons from './UnbookedLessons'
import PromoteFMI from './PromoteFMI'
const Widgets = { MyCalendar, MyAccount, Support, NextLesson, UnbookedLessons, Notifications }
export { MyCalendar, MyAccount, Support, NextLesson, UnbookedLessons, Notifications, PromoteFMI }
export default Widgets
