import PortalStudentProfile from '@classes/StudentProfile'
import AtRiskNotificationCard from '@components/AtRiskNotificationCard'
import LPUnavailableAlert, { IPLPUnavailableAlert } from '@components/LPUnavailableAlert'
import HeaderWithClock from '@components/PageHeader'
import { StoreProps } from '@interfaces/StoreState'
import { PROFILE_TYPE } from '@utils/constants'
import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-styled-flexboxgrid'

interface IDashboardHeader {
  loading?: boolean
  LPUnavailable?: boolean
  LPUnavailableLabels?: IPLPUnavailableAlert
}

const DashboardHeader: React.FC<IDashboardHeader> = ({ loading, LPUnavailable, LPUnavailableLabels }) => {
  const { isStudent, profile } = useSelector(({ userProfile }: StoreProps) => ({
    isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
    profile: new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo),
  }))

  return (
    <>
      {profile.AtRiskProgram || LPUnavailable ? (
        <Row>
          <Col lg={6} md={12} xs={12}>
            {profile.AtRiskProgram ? (
              <>
                <AtRiskNotificationCard />
              </>
            ) : (
              <LPUnavailableAlert loading={loading} {...LPUnavailableLabels} />
            )}
          </Col>
          <Col lg={6} md={12} xs={12}>
            <HeaderWithClock title={isStudent ? undefined : 'My dashboard'} />
          </Col>
        </Row>
      ) : (
        <HeaderWithClock title={isStudent ? undefined : 'My dashboard'} />
      )}
    </>
  )
}

export default DashboardHeader
