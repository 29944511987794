import DOC from '@berlitz/streamline-icons/lib/light/07-Work-Office-Companies/07-Office-Files/OfficeFileDoc'
import PDF from '@berlitz/streamline-icons/lib/light/07-Work-Office-Companies/07-Office-Files/OfficeFilePdf'
import PPT from '@berlitz/streamline-icons/lib/light/07-Work-Office-Companies/07-Office-Files/OfficeFilePpt'
import RTF from '@berlitz/streamline-icons/lib/light/07-Work-Office-Companies/07-Office-Files/OfficeFileRtf'
import XLS from '@berlitz/streamline-icons/lib/light/07-Work-Office-Companies/07-Office-Files/OfficeFileSheet'
import Image from '@berlitz/streamline-icons/lib/light/13-Images-Photography/05-Pictures/PictureLandscape'
import AUDIO from '@berlitz/streamline-icons/lib/light/14-Music-Audio/18-Audio-Files/AudioFile'
import VIDEO from '@berlitz/streamline-icons/lib/light/15-Video-Movies-TV/08-Video-Files/VideoFileCamera'
import OTHERS from '@berlitz/streamline-icons/lib/light/16-Files-Folders/01-Common-Files/CommonFileEmpty'
import TXT from '@berlitz/streamline-icons/lib/light/16-Files-Folders/01-Common-Files/CommonFileText'

export const getIcon = (type: string) => {
  switch (type) {
    case 'image/jpeg':
    case 'jpeg':
    case 'jpg':
    case 'image/gif':
    case 'gif':
    case 'image/png':
    case 'png':
      return {
        Icon: Image,
      }
    case 'video/mp4':
    case 'mp4':
    case 'video/avi':
    case 'avi':
      return {
        Icon: VIDEO,
      }
    case 'audio/mp3':
    case 'mp3':
    case 'audio/wav':
    case 'wav':
      return {
        Icon: AUDIO,
      }
    case 'text/rtf':
    case 'rtf':
      return {
        Icon: RTF,
      }
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'doc':
    case 'docx':
      return {
        Icon: DOC,
      }
    case 'application/msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'text/csv':
    case 'xls':
    case 'ods':
    case 'xlsx':
    case 'csv':
      return {
        Icon: XLS,
      }
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'ppt':
    case 'pptx':
      return {
        Icon: PPT,
      }
    case 'text/plain':
    case 'application/vnd.oasis.opendocument.text':
    case 'txt':
    case 'odt':
      return {
        Icon: TXT,
      }
    case 'application/pdf':
    case 'pdf':
      return {
        Icon: PDF,
      }

    default:
      return {
        Icon: OTHERS,
      }
  }
}

export default { Image, PDF, TXT }
