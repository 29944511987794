import PortalStudentProfile from '@classes/StudentProfile'
import { ModalAllYouCanLearnCompletion } from '@components/AllLevelsCompletionModal'
import { StoreProps } from '@interfaces/StoreState'
import {
  CURRICULUM_TEST_STATUS,
  AllLevelsCompletedModalLocalStorageKey,
  AllLevelsCompletedLocalStorageKey,
} from '@utils/constants'
import React from 'react'
import { useSelector } from 'react-redux'

export const CurriculumTestCompletionModal: React.FC = () => {
  const { studentProfile, TestUnlockedMaterial, WithPermission, CurriculumTestMaterial } = useSelector(
    ({ userProfile, permissions }: StoreProps) => {
      const studentProfile = new PortalStudentProfile(userProfile.info?.StudentProfile?.StudentProfileInfo)
      const TestUnlockedMaterial = studentProfile.LPMaterialDropDown.find(
        ({ material }) =>
          material?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED &&
          !localStorage.getItem(AllLevelsCompletedModalLocalStorageKey(material?.LPID, studentProfile?.SFId))
      )

      const WithPermission = permissions.permissions['flag::CT-feature']?.show

      return {
        studentProfile,
        TestUnlockedMaterial,
        WithPermission,
        CurriculumTestMaterial: TestUnlockedMaterial?.suboptions.find(
          ({ material }) =>
            material?.IsTest &&
            material?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED &&
            !localStorage.getItem(AllLevelsCompletedModalLocalStorageKey(material?.LPID, studentProfile?.SFId))
        ),
      }
    }
  )

  if (!TestUnlockedMaterial || !CurriculumTestMaterial) {
    return null
  }

  if (WithPermission) {
    return null
  }

  return (
    <ModalAllYouCanLearnCompletion
      MaterialName={TestUnlockedMaterial.material.MaterialName}
      CurriculumTestId={CurriculumTestMaterial?.material.LPID}
      AllLevelsCompletedKey={AllLevelsCompletedLocalStorageKey(TestUnlockedMaterial.material.LPID, studentProfile.SFId)}
      AllLevelsCompletedModalKey={AllLevelsCompletedModalLocalStorageKey(
        TestUnlockedMaterial.material.LPID,
        studentProfile.SFId
      )}
      CurriculumTestModalKey={AllLevelsCompletedModalLocalStorageKey(
        CurriculumTestMaterial.material.LPID,
        studentProfile.SFId
      )}
      defaultOpenModal={true}
      isFlex={false}
      isOnDemand={false}
    />
  )
}

export default CurriculumTestCompletionModal
