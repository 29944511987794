import { CaseData } from '@interfaces/Cases'
import get from 'lodash/get'

const STATUS_PRIORITY = {
  New: 4,
  Working: 3,
  'On Hold': 2,
  'Re-opened': 1,
}

class PortalCases {
  cases?: CaseData[]
  constructor(cases: CaseData[]) {
    this.cases = cases
  }

  getPriority(status) {
    return get(STATUS_PRIORITY, status, 0)
  }

  get SortedCases() {
    /**
     * Heads up! sort fn is a mutable operation, so we need to clone the array first
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sort_returns_the_reference_to_the_same_array
     */
    return this.cases
      ?.slice()
      .sort(
        (a, b) =>
          new Date(b.CaseInfo.LastModifieDatetime || 0).getTime() -
            new Date(a.CaseInfo.LastModifieDatetime || 0).getTime() ||
          new Date(b.CaseInfo.CreatedDatetime || 0).getTime() - new Date(a.CaseInfo.CreatedDatetime || 0).getTime() ||
          this.getPriority(b.CaseInfo.Status) - this.getPriority(a.CaseInfo.Status)
      )
  }
}

export default PortalCases
