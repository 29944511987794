import ListLoader from './ListLoader'
import { IconCircle, List, ListItem, ListItemContainer } from './style'
import WidgetCard from './WidgetCard'
import WidgetContent from './WidgetContent'
import WidgetFooter from './WidgetFooter'
import WidgetHeader from './WidgetHeader'

const Common = {
  WidgetCard,
  WidgetHeader,
  WidgetContent,
  WidgetFooter,
  List,
  ListItem,
  ListLoader,
  IconCircle,
  ListItemContainer,
}
export {
  WidgetCard,
  WidgetHeader,
  WidgetContent,
  WidgetFooter,
  List,
  ListItem,
  ListLoader,
  IconCircle,
  ListItemContainer,
}

export default Common
