import * as React from 'react'

import { WidgetHeaderProp } from './interface'
import { Header, HeaderIcon, HeaderTitle } from './style'

const WidgetHeader: React.FC<WidgetHeaderProp> = ({
  icon,
  title,
  color = 'disabled',
  className,
  headerColor,
  withCountdown,
  customStyling,
}) => (
  <Header
    className={`${!icon && 'no-icon'} ${className}`}
    headerColor={headerColor}
    withCountdown={withCountdown}
    style={customStyling}
  >
    {icon && <HeaderIcon color={color}>{icon}</HeaderIcon>}
    <HeaderTitle>{title}</HeaderTitle>
  </Header>
)

export default WidgetHeader
