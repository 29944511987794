import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.space.sm}`};
  text-align: center;

  img {
    width: 166px;
  }

  button {
    padding-top: ${({ theme }) => theme.space.xxs};
    padding-bottom: ${({ theme }) => theme.space.xxs};
  }
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.charcoal};
`
