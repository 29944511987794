import Spacer from '@berlitz/spacer'
import random from 'lodash/random'
import * as React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import Resizable from '../../../interfaces/Resizable'
import { ListLoaderProp } from './interface'
import { List, ListItem } from './style'

const LeftContent = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 8px;
  }
`

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100px;

  div {
    margin-left: 8px;
  }
`

const LoadUI: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={42}
    width={500}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  />
)

const IconLoadUI: React.FC<Resizable> = ({ height, width = '100%' }) => (
  <ContentLoader
    height={38}
    width={38}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ height, width }}
  >
    <circle cx="20" cy="20" r="19" />
  </ContentLoader>
)

const Loader: React.FC<ListLoaderProp> = ({ listCount, withLeftIcon, withRightContent }) => (
  <List>
    {[...Array(listCount ? listCount : 5).keys()].map((key: number) => (
      <ListItem key={`list-loader-${key}`} className="no-padding">
        {withLeftIcon ? (
          <LeftContent>
            <div>
              <IconLoadUI height="25px" width="25px" />
            </div>
            <LoadUI height="16px" width={`${random(80, 150)}px`} />
          </LeftContent>
        ) : (
          <>
            <LoadUI height="16px" width={`${random(80, 150)}px`} />
            <Spacer size="lg" />
          </>
        )}

        {withRightContent && (
          <RightContent>
            <LoadUI height="15px" width={`${random(50, 70)}px`} />
          </RightContent>
        )}
      </ListItem>
    ))}
  </List>
)

export default Loader
