import { Row } from 'react-styled-flexboxgrid'
import styled, { css } from 'styled-components'

export const LPWidgetOverride = styled.div`
  & > div {
    width: 100%;
    border-radius: 3px;
    height: 296px;
  }

  & > div > div {
    width: 100%;
  }
`

export const LpCtCardOverride = styled(LPWidgetOverride as any)<{ status: string }>`
  ${({ status }) =>
    status === 'Unlocked'
      ? css`
          & > div {
            background-position:
              left 50% top -92px,
              left 50% bottom -35px;
            background-size: 270px, 298px;
          }

          button {
            background-color: #45b55a;
          }
        `
      : status === 'Locked'
        ? css`
            & > div {
              background-position: left 50% bottom -35px;
              background-size: 298px;
            }
          `
        : css`
            & > div {
              background-position:
                left 50% top -10px,
                left 50% bottom -45px;
              background-size: 270px, 228px;
            }
          `}
`

export const LpCtCertCardOverride = styled(LPWidgetOverride as any)`
  & > div {
    background-position:
      left 50% top -92px,
      left 50% bottom -18px;
    background-size: 270px, 270px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const CenteredRow = styled(Row as any)`
  justify-content: center;
`
