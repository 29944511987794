import { H1 } from '@berlitz/globals'
import { StoreProps } from '@interfaces/StoreState'
import { HeroContent } from '@layouts/Dashboard/HeroBlock/interface'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { useSelector } from 'react-redux'

const HeroHeader: React.FC<HeroContent> = ({ content, loading, newStudent }) => {
  const { name } = useSelector(({ userProfile }: StoreProps) => ({
    name: userProfile?.info?.StudentProfile?.StudentProfileInfo?.FirstName,
  }))

  const heroTitle = !newStudent ? content?.returning_students_title : content?.title
  if (loading) {
    return <ContentLoader height={38} width={500} speed={2} primaryColor="#ffffff" secondaryColor="#ecebeb" />
  }
  if (heroTitle) {
    return <H1 disableMargin>{heroTitle.replace('{user}', name)}</H1>
  }
  return null
}

export default HeroHeader
