import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  border: 2px solid ${({ theme }) => theme.colors.brandPrimary};
  border-radius: ${({ theme }) => theme.radius.lg};
  display: flex;
  align-items: stretch;
  width: 100%;
  overflow-wrap: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: max-content;
  }
`

export const Details = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.space.xxs} ${theme.space.xs}`};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Title = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.space.xs};
    margin-bottom: ${theme.space.xxxs};
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.bold};
    line-height: 1.38;
    letter-spacing: 0.56px;
    color: ${theme.colors.brandPrimary};
  `};
`

export const Description = styled.div`
  ${({ theme }) => `
    margin-left: ${theme.space.xs};
    font-size: ${theme.fontSize.xxs};
    line-height: 1.14;
    letter-spacing: 0.49px;
    color: ${theme.palette.charcoal};
  `};
`
