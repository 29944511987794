import PortalProgram from '@classes/Program'
import { usePortalFeatures } from '@components/RoleBasedView'
import { StoreProps } from '@interfaces/StoreState'
import { EVENT_TYPES } from '@utils/constants/events'
import React from 'react'
import { useSelector } from 'react-redux'

export interface IPEventOrientationProvider {}

export type TGetType = (Type: string, ProgramId?: string, RecordId?: string) => string

export interface IEventOrientationContext {
  getType: TGetType
}

export const EventOrientationContext = React.createContext<IEventOrientationContext>({} as IEventOrientationContext)

export const EventOrientationProvider: React.FC<IPEventOrientationProvider> = ({ children }) => {
  const { state } = usePortalFeatures({
    configId: 'c::events::getting-started',
  })

  const { profile, IsStudent } = useSelector(({ userProfile }: StoreProps) => ({
    profile: userProfile.info,
    IsStudent: userProfile.info.Type === 'Student',
  }))

  const getType: TGetType = (Type = '', ProgramId = '', RecordId = '') => {
    const label = Type === EVENT_TYPES.ORIENTATION ? EVENT_TYPES.GETTING_STARTED : Type
    if (!IsStudent) return label
    if (!state.allowed) return label

    if (ProgramId || RecordId) {
      const recordProgramId =
        profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers?.find(
          ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === RecordId
        )?.RegistrationInfo?.Programs?.[0]?.ProgramId || ProgramId

      const selectedProgramId = RecordId ? recordProgramId : ProgramId
      const StudentProgram = profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers?.find(
        ({ ProgramStudentInfo }) => ProgramStudentInfo?.ProgramId === selectedProgramId
      )

      const Program = new PortalProgram(StudentProgram?.ProgramStudentInfo)
      const label = Program.isProgramLiveOnline() ? EVENT_TYPES.GETTING_STARTED : EVENT_TYPES.ORIENTATION

      return Type === EVENT_TYPES.ORIENTATION ? label : Type
    }

    return Type
  }

  return <EventOrientationContext.Provider value={{ getType }}>{children}</EventOrientationContext.Provider>
}

export default EventOrientationProvider
