import Spacer from '@berlitz/spacer'
import Compass1 from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/01-Compass/Compass1'
import VideoModal from '@components/Modal/VideoModal'
import { HeroContent } from '@layouts/Dashboard/HeroBlock/interface'
import { BodyWrapper, WatchWrapper } from '@layouts/Dashboard/HeroBlock/style'
import { createMarkup } from '@utils/helpers'
import React from 'react'
import ContentLoader from 'react-content-loader'

const HeroSubHeader: React.FC<HeroContent> = ({ content, loading, hide, newStudent }) => {
  const body = !newStudent && content?.returning_students_body ? content?.returning_students_body : content?.body
  const { title, href } = content?.orientation_video_cta || {}
  const [modalOpen, setModalOpen] = React.useState(false)

  if (hide) {
    return null
  }
  return (
    <>
      <Spacer size="sm" />
      {loading ? (
        <ContentLoader height={30} width={500} speed={2} primaryColor="#ffffff" secondaryColor="#ecebeb" />
      ) : (
        <BodyWrapper dangerouslySetInnerHTML={createMarkup(body)} />
      )}
      {title && href && !loading && (
        <WatchWrapper>
          <Compass1 size="md" />
          <a onClick={() => setModalOpen(true)}>{title}</a>
        </WatchWrapper>
      )}
      <Spacer size="lg" />
      {href && (
        <VideoModal open={modalOpen} onClose={() => setModalOpen(false)} src={href} title="Orientation" playing />
      )}
    </>
  )
}

export default HeroSubHeader
