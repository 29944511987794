import PortalStudentProfile from '@classes/StudentProfile'
import { StoreProps } from '@interfaces/StoreState'
import ProgramSelector from '@layouts/Dashboard/HeroBlock/ProgramSelector'
import { updateDashboardActiveMaterial } from '@redux/actions/dashboard'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/**
 * This is a wrapper component that will help to initialize the active material
 * on page load. This is needed because the active material usually get data
 * from local storage and sometimes its not what we want to show on initial page load.
 *
 */
const HeroBlockInitializer = ({ children }) => {
  const dispatch = useDispatch()
  const { portalStudentInfo, activeMaterial } = useSelector(({ userProfile, dashboard }: StoreProps) => {
    const portalStudentInfo = userProfile?.info?.StudentProfile?.StudentProfileInfo
      ? new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo)
      : {
          MaterialDropDown: [],
          getActiveLPIdFromMaterialDropdown: () => '',
          storeActiveDropdownLPId: () => '',
        }
    return {
      portalStudentInfo,
      activeMaterial: dashboard.activeMaterial,
    }
  })

  const activeDropdownLPId = portalStudentInfo?.getActiveLPIdFromMaterialDropdown()

  useEffect(() => {
    if (!activeMaterial && activeDropdownLPId) {
      dispatch(updateDashboardActiveMaterial(activeDropdownLPId))
    }
  }, [])

  useEffect(() => {
    if (activeDropdownLPId) {
      portalStudentInfo?.storeActiveDropdownLPId(activeDropdownLPId)
    }
  }, [activeDropdownLPId])

  return (
    <>
      <ProgramSelector showCT defaultProgram={activeDropdownLPId} hide />
      {<>{children}</>}
    </>
  )
}

export default HeroBlockInitializer
