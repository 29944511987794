import styled from 'styled-components'
import { IconCircle, ListItem } from '../Common'

export const MyCalendarList = styled.div`
  padding: 6px 0;

  ${ListItem} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`

export const StatusBox = styled.div<{ children: any }>`
  display: flex;
  align-items: center;
  width: max-content;
  font-size: ${({ theme }) => theme.fontSize.xxs};

  ${IconCircle} {
    margin-left: ${({ theme }) => theme.space.sm};
  }
`
