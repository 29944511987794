import React from 'react'
import PortalLearningPaths from '@classes/LearningPaths'
import QueryLearningPath from '@layouts/LearningPath/QueryLearningPathv2'
import useAchievements from '@hooks/useAchievements'
import { AchievementInfos } from '@interfaces/Achievements'

export interface ChildrenProp {
  loading: boolean
  Achievements: AchievementInfos[]
  LearningPaths: PortalLearningPaths
  error: Error
}

interface Props {
  children?: (props: ChildrenProp) => React.ReactNode
}

const QueryDashboard: React.FC<Props> = ({ children }) => {
  const { loading: achievementLoading, Achievements } = useAchievements()

  return (
    <QueryLearningPath level="core">
      {({ loading, error, LearningPaths }: { loading: boolean; error: any; LearningPaths: PortalLearningPaths }) => {
        // @ts-ignore
        return children({ loading: achievementLoading || loading, error, LearningPaths, Achievements })
      }}
    </QueryLearningPath>
  )
}

export default QueryDashboard
