import styled from 'styled-components'
import { IPromoteFMIContent } from '.'

export const Wrapper = styled.div<IPromoteFMIContent>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    color: ${({ theme }) => theme.colors.brandPrimary};
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
  }

  ${({ theme, isBookingTab }) =>
    isBookingTab &&
    `
      height: 120px;
      position: initial;

      ${PromoteFMIWrapper} {
        width: 100%;
        height: 176px;
        position: absolute;
        top: 0;
        text-align: left;

        h3 {
          font-size: ${theme.fontSize.xs};
          font-weight: ${theme.fontWeight.bold};
          margin-bottom: 8px;
        }
      
        p {
          font-size: ${theme.fontSize.xxxs};
          margin-bottom: 12px;
          z-index: 1;
        }
      
        button {
          margin-left: 0px;
        }

        section {
          bottom: 0px;
          text-align: center;
          height: 116px;
        }
      }

    @media (min-width: ${theme.breakpoints.sm}) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      
      ${PromoteFMIWrapper} {
        width: 100%;
        height: 100%;
        text-align: left;
        padding: 12px 16px;

        section {
          bottom: -4px;
          text-align: center;
        }
      }
    }
  `}
`

export const PromoteFMIWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 21px 15px;
    height: calc(100% + 55px);
    background-color: ${theme.palette.info100};
    color: white;
    position: relative;
    width: 100%;
    height: 100%;

    h3 {
      font-size: ${theme.fontSize.sm};
      font-weight: ${theme.fontWeight.bold};
      margin-bottom: 10px;
      color: white;
    }
    
    p {
      font-size: ${theme.fontSize.xxs};
      margin-bottom: 22px;
    }
    
    button {
      width: max-content;
      margin: auto;
      margin-top: 0;
      color: ${theme.palette.charcoal};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
      background-color: #3ddc97;
      padding: 9px 16px;
      position: relative;
      z-index: 1;
    }

    section {
      position: absolute;
      width: 92%;
      bottom: -2px;
      margin: auto;
    }
  `}
`
