import { StoreProps } from '@interfaces/StoreState'
import React from 'react'
import { Query } from 'react-apollo'
import { useSelector } from 'react-redux'
import QueryResult from '../../interfaces/QueryResult'
import { GET_CASES } from '../../queries/cases'

interface QueryPageResults {
  loading: boolean
  error?: Error
  data: any
}

interface QuerySupportDataProps {
  Id: string
  Type: string
  children: (results: QueryPageResults) => {}
}

const QuerySupportData: React.FC<QuerySupportDataProps> = ({ Id, Type, children }) => {
  const {
    casesLoading: cLoading,
    cases: cData,
    casesError: cError,
  } = useSelector(({ preloadedData }: StoreProps) => preloadedData)

  return (!cLoading && !cData) || cError ? (
    <Query
      query={GET_CASES}
      variables={{
        Id,
        Type,
        UserId: Id,
      }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult) => {
        return children({
          loading,
          error,
          data,
        })
      }}
    </Query>
  ) : (
    <>
      {children({
        loading: Boolean(cLoading),
        error: cError,
        data: cData,
      })}
    </>
  )
}

export default QuerySupportData
