import RectLoader, { CircleLoader } from '@components/Loader'
import React from 'react'
import Spacer from '@berlitz/spacer'
import { CenteredRow, Container } from './style'

const CTWidgetLoader: React.FC = () => {
  return (
    <Container>
      <CenteredRow>
        <CircleLoader width="52px" height="52px" />
        <Spacer size="md" />
        <RectLoader width="30%" height="21px" />
        <Spacer size="xs" />
        <RectLoader width="60%" height="21px" />
        <Spacer size="sm" />
        <RectLoader width="40%" height="21px" />
        <Spacer size="sm" />
        <RectLoader width="90px" height="35px" />
        <Spacer size="xl" />
        <Spacer size="lg" />
      </CenteredRow>
    </Container>
   
  )
}

export default CTWidgetLoader