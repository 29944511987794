import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { Label, Wrapper } from './style'

interface Prop {
  buttonLabel?: React.ReactNode
  buttonLink?: string
  icon?: string
  noButtons?: boolean
  msg?: string
}

const EmptyEvents: React.FC<InjectedIntlProps & Prop> = ({
  intl,
  icon = '/images/empty-events-24.svg',
  noButtons,
  buttonLabel = '',
  buttonLink = '',
  msg = 'No lessons scheduled.',
}) => {
  return (
    <Wrapper>
      <Label>
        <FormattedMessage id={msg} />
        <Spacer size="md" />
      </Label>

      <div>
        <img src={icon} alt={intl.formatMessage({ id: 'No lessons scheduled.' })} width={'100%'} />
      </div>

      {!noButtons && (
        <div>
          <Spacer size="xs" />
          <Button onClick={() => Router.push(buttonLink)} small>
            {buttonLabel}
          </Button>
        </div>
      )}
    </Wrapper>
  )
}

export default injectIntl(EmptyEvents)
