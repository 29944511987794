import Button from '@berlitz/button'
import { Profile, TickSquare } from '@berlitz/icons'
import Cog from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/12-Settings/Cog'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import IconTranslate from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/35-Text-Options/Translate'
import TimeClockCircle2 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/18-Time/TimeClockCircle2'
import { StoreProps } from '@interfaces/StoreState'
import { FEATURE_TYPE, PROFILE_TYPE, PROGRAM_TYPES } from '@utils/constants'
import Link from 'next/link'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import IconWrapper from '../../IconWrapper'
import { List, ListItem, WidgetCard, WidgetContent, WidgetFooter, WidgetHeader } from '../Common'
import { LinkProp } from './interface'
import { MyAccountList } from './style'
import useTimeFormat from '@hooks/useTimeFormat'
import PortalStudentProfile from '@classes/StudentProfile'

const MyAccount: React.FC = (props) => {
  const intl = useIntl()
  const { isStudent, isOnDemand, studentProfile, activeMaterial } = useSelector(
    ({ userProfile, dashboard }: StoreProps) => ({
      isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
      isOnDemand: userProfile?.info?.FeatureType?.includes(FEATURE_TYPE.ON_DEMAND),
      studentProfile: new PortalStudentProfile(userProfile.info?.StudentProfile?.StudentProfileInfo),
      activeMaterial: dashboard?.activeMaterial,
    })
  )
  const { isTimeFormatDisabled } = useTimeFormat()

  let Links: LinkProp[] = [
    {
      icon: <Profile color="brand07" />,
      href: '/instructors/settings',
      name: intl.formatMessage({ id: 'Edit my profile' }),
    },
    {
      icon: <Calendar1 color="brand07" />,
      href: '/instructors/settings/availability',
      name: intl.formatMessage({ id: 'Availability' }),
    },
    {
      icon: <IconTranslate color="brand07" />,
      href: '/instructors/settings/account',
      name: intl.formatMessage({ id: 'Change display language' }),
    },
  ]

  if (isStudent) {
    Links = [
      {
        icon: <IconTranslate color="brand07" />,
        href: '/settings/account',
        name: intl.formatMessage({ id: 'Change display language' }),
      },
    ]

    const additionalLinks = [
      {
        icon: <TickSquare color="brand07" />,
        href: '/settings/lesson-history',
        name: intl.formatMessage({ id: 'View my lesson history' }),
        exclude: isOnDemand,
      },
      {
        icon: (
          <span className="grayed-icon">
            <IconWrapper>
              <Cog />
            </IconWrapper>
          </span>
        ),
        href: '/settings/learning',
        name: intl.formatMessage({ id: 'Update my preferences' }),
        exclude: isOnDemand,
      },
      ...Links,
    ]

    const program = studentProfile?.getProgramByMaterialId(activeMaterial ?? '')
    if (
      program &&
      ((PROGRAM_TYPES.ON_DEMAND.indexOf(program?.ProgramTypeDisplay ?? '') >= 0 && program?.IsGroupConversation) ||
        studentProfile.HasBoD1on1Registration)
    ) {
      Links = additionalLinks
    } else if (program && PROGRAM_TYPES.ON_DEMAND.indexOf(program?.ProgramTypeDisplay ?? '') === -1) {
      Links = additionalLinks
    }
  }

  if (!isTimeFormatDisabled) {
    Links = [
      ...Links,
      {
        icon: <TimeClockCircle2 color="brand07" />,
        href: `${isStudent ? '' : '/instructors'}/settings/account`,
        name: intl.formatMessage({ id: 'Change time format' }),
      },
    ]
  }

  return (
    <WidgetCard dataPermission={props['data-c-permission']}>
      <WidgetHeader
        icon={
          <IconWrapper>
            <Cog />
          </IconWrapper>
        }
        title={intl.formatMessage({ id: 'My account' })}
        color="disabled"
      />
      <WidgetContent>
        <MyAccountList>
          <List>
            {Links.filter(({ exclude }) => !exclude).map(({ icon, href, name }, index: number) => (
              <ListItem key={`account-item-${index}`}>
                {icon}
                <Link href={href}>{name}</Link>
              </ListItem>
            ))}
          </List>
        </MyAccountList>
      </WidgetContent>
      <WidgetFooter>
        <Button
          fullWidth
          onClick={() => Router.push(`${isStudent ? '/settings/account' : '/instructors/settings/account'}`)}
        >
          <FormattedMessage id="View my account" />
        </Button>
      </WidgetFooter>
    </WidgetCard>
  )
}

export default MyAccount
