import styled, { css } from 'styled-components'

export const Suggestion = styled.div`
  ${({ theme }) => css`
    border: 1px solid;
    border-left: ${theme.space.xs} solid;
    border-radius: ${theme.space.xxs};
    border-color: ${theme.palette.warning100};
    padding: ${theme.space.xs} ${theme.space.sm};
    background-color: ${theme.palette.warning10};
    margin-top: ${theme.space.md};

    .program-name {
      border-radius: 3px;
      background-color: ${theme.palette.white};
      font-size: ${theme.space.sm};
      font-weight: ${theme.fontWeight.bold};
      padding: ${theme.space.xxxs} ${theme.space.xxs};
      overflow-wrap: break-word;
      margin-bottom: ${theme.space.xs};
    }

    .content {
      display: flex;
      justify-content: space-between;
      font-size: ${theme.fontSize.xxs};

      button {
        padding-top: ${theme.space.xs};
        padding-bottom: ${theme.space.xs};
        width: max-content;
      }
    }
  `}
`
