import invert from 'lodash/invert'

const mapped = {
  Billing: 'Payment',
}

const mappedV2 = {
  Payment: 'Payment',
  'Technical Support': 'Techincal support',
  'My calendar': 'My LessonModify Schedule',
  'My course': 'My Lesson',
  'My learning': 'Feedback',
}

const mappedInstructor = { ...mappedV2, Feedback: 'Feedback' }

export const convertPortalToSFValue = (type, useV2?: boolean, isInstructor?: boolean) => {
  return (useV2 ? (isInstructor ? mappedInstructor : mappedV2) : mapped)[type]
}

export const convertSFtoPortalValue = (type, useV2?: boolean, isInstructor?: boolean) => {
  const inverted = invert(useV2 ? (isInstructor ? mappedInstructor : mappedV2) : mapped)
  return inverted[type]
}
