import QueryResult from '@interfaces/QueryResult'
import { StoreProps } from '@interfaces/StoreState'
import { GET_SCHEDULES_ID } from '@queries/index'
import moment from 'moment'
import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { useSelector } from 'react-redux'

interface QueryPageResults {
  loading: boolean
  error?: Error
  data: any
}

interface QueryScheduleDataProps {
  query?: any
  Id: string
  FilterType: string
  Limit?: number
  FromDate?: string
  EndDate?: string
  children: (results: QueryPageResults) => {}
}

const QueryScheduleData: React.FC<QueryScheduleDataProps> = ({
  query,
  Id,
  FilterType,
  Limit,
  FromDate,
  EndDate,
  children,
}) => {
  // put inside state for this to not refetch
  const [dates] = useState({
    start: moment.utc().format(),
    end: moment.utc(moment().add(30, 'days')).format(),
  })

  const plData = useSelector(({ preloadedData }: StoreProps) => preloadedData)

  const mapping = {
    FILTER_STUDENT_NEXT2_SESSION: 'nextSession',
    FILTER_STUDENT_MY_CALENDER: 'myCalendar',
  }

  const filterLoading = plData[`${mapping[FilterType]}Loading`]
  const filterData = plData[mapping[FilterType]]
  const filterError = plData[`${mapping[FilterType]}Error`]

  return (!filterLoading && !filterData) || filterError ? (
    <Query
      query={query || GET_SCHEDULES_ID}
      variables={{
        Id,
        FilterType,
        Limit: Limit || 30,
        FromDate: FromDate || dates.start,
        EndDate: EndDate || dates.end,
      }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult) => {
        return children({
          loading,
          error,
          data,
        })
      }}
    </Query>
  ) : (
    <>
      {children({
        loading: filterLoading,
        error: filterError,
        data: filterData,
      })}
    </>
  )
}

export default React.memo(QueryScheduleData)
